<template>
  <div></div>
</template>

<script>
import { login } from "@/api/login";
import to from "@/utils/to";

export default {
  mounted() {
    const { code } = this.$route.query;
    this.auth(code);
  },
  methods: {
    async auth(code) {
      this.$showLoading("登陆中...");
      let [err, res] = await to(login({ code }));
      this.$hideLoading();

      if (err != null) {
        console.log("登陆错误", err);
        return;
      }

      console.log("authpage login --->", res);
      console.log("登陆成功", res);
      this.$store.commit("setUser", res);

      const local = decodeURIComponent(
        decodeURIComponent(this.$route.params.url)
      );

      console.log("local:", local);
      location.replace(local);
    },
  },
};
</script>

<style>
</style>